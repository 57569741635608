<template>
  <div class="page">
  		<webHeader :currentIndex="currentIndex" :themes="'white'"></webHeader>
		<div class="pageLogin">
			<div class="imgArea">
				<img class="image" src="../../../assets/web/img/loginBg.png">
			</div>
			<div class="form">
				<div class="title">{{$t('webTitle.register')}}</div>
				<van-tabs line-width="0px" line-height="0px" >
					<!-- <van-tab :title="$t('register.phonereg')">
						<van-row>
						  <van-form ref="phoneform">
							  <van-field v-model="form.username" name="username" clearable :placeholder="$t('register.enterphone')" class="input" :rules="phonerules" >
							    <template #label>
								  <span class="selectBlock">{{langCode}}
									  <van-dropdown-menu active-color="#1989fa"  class="menuStyle">
										<van-dropdown-item id="item" ref="item">
											<div class="list" :class="langCode==item.text?'selectItem':''" v-for="(item,index) in option" :key="item.id" @click="handelChange(item)">
												<img class="image" :src="item.icon">
												<span class="guojia">{{item[lang+'name']}}</span>
												<span class="area">{{item.text}}</span>
											</div>
										</van-dropdown-item>
									  </van-dropdown-menu>
								  </span>
							    </template>
							    <template #left-icon>
							      <van-icon class="iconfont shouji" class-prefix="icon" name="shouji" />
							    </template>
							  </van-field>
							  <van-field v-model="form.sms" clearable center :placeholder="$t('forget.entercode')" :rules="smsrules" class="input" style="background-color: #00000000;" >
							      <template #left-icon>
							        <van-icon class="iconfont yanzheng" class-prefix="icon" name="yanzhengma" />
							      </template>
							      <template #button>
							        <van-button v-if="sendsmsbtn" size="small"  @click.prevent="sendsms" >{{ $t("forget.sendcode") }}</van-button >
							        <van-button size="small" disabled v-else >{{ sendsmstime }}S</van-button >
							      </template>
							    </van-field>
							  <van-field v-model="form.password" :type="type" name="password" :right-icon="eye" :placeholder="$t('register.enterpassword')" @click-right-icon="openeye" :rules="passrules" class="input" >
							    <template #left-icon>
							      <van-icon class="iconfont" class-prefix="icon" name="ziyuanxhdpi" />
							    </template>
							  </van-field>
							  <van-field v-model="form.repassword" :type="type" name="repassword" :right-icon="eye" :placeholder="$t('register.enterpassword')" @click-right-icon="openeye" :rules="repassrules" class="input" >
							    <template #left-icon>
							      <van-icon class="iconfont" class-prefix="icon" name="ziyuanxhdpi" />
							    </template>
							  </van-field>
							  <van-field v-model="form.invite_code" name="invite_code" :placeholder="$t('PleaseEnterTheInvitationCode')" :rules="invirules" maxlength="6" class="input" >
							  	<template #left-icon>
							  	  <van-icon class="iconfont yaoqing" class-prefix="icon" name="zengjiarenwu" />
							  	</template>
							  </van-field>
						  </van-form>
						</van-row>
					</van-tab> -->
					<!-- :title="$t('register.emailreg')" -->
					<van-tab >
						<van-row>
							<van-form ref="emailform">
							  <van-field v-model="form.username" name="username" clearable :placeholder="$t('register.enteremail')" :rules="emailrules" class="input" >
								  <template #left-icon>
								  		<van-icon class="iconfont" class-prefix="icon" name="youxiang" />
								  </template>
							  </van-field>
							  <van-field v-model="form.sms" clearable center :placeholder="$t('forget.entercode')" :rules="smsrules" class="input" >
							      <template #left-icon>
									  <van-icon class="iconfont yanzheng" class-prefix="icon" name="yanzhengma" />
							      </template>
							      <template #button>
							        <van-button v-if="sendemailbtn" size="small" @click.prevent="sendemail" >{{ $t("forget.sendcode") }}</van-button >
							        <van-button size="small" disabled v-else >{{ sendemailtime }}S</van-button >
							      </template>
							    </van-field>
							  <van-field v-model="form.password" :type="type" name="password" :right-icon="eye" :placeholder="$t('register.enterpassword')" @click-right-icon="openeye" :rules="passrules" class="input" >
								  <template #left-icon>
								  		<van-icon class="iconfont" class-prefix="icon" name="ziyuanxhdpi" />
								  </template>
							  </van-field>
							  <van-field v-model="form.repassword" :type="type" name="repassword" :right-icon="eye" :placeholder="$t('register.enterpassword')" @click-right-icon="openeye" :rules="repassrules" class="input" >
								  <template #left-icon>
								  		<van-icon class="iconfont" class-prefix="icon" name="ziyuanxhdpi" />
								  </template>
							  </van-field>
							  <van-field v-model="form.invite_code" name="invite_code" :placeholder="$t('PleaseEnterTheInvitationCode')" :rules="invirules" maxlength="6" class="input" >
								  <template #left-icon>
								  		<van-icon class="iconfont yaoqing" class-prefix="icon" name="zengjiarenwu" />
								  </template>
							  </van-field>
							</van-form>
						</van-row>
					</van-tab>
				</van-tabs>
				<van-button
				  v-if=" form.username && form.password && form.repassword && form.invite_code "
				  class="mybtnTo"
				  :loading="loading"
				  :loading-text="$t('register.registering')"
				  @click="register"
				>
				  {{ $t("register.quickreg") }}
				</van-button>
				<van-button
				  class="mybtn"
				  v-else
				  :loading="loading"
				  :loading-text="$t('register.registering')"
				>
				  {{ $t("register.quickreg") }}
				</van-button>
				<div class="footer">
					<span class="gray">{{ $t("register.yizhuce") }}？</span><span class="pointer" @click="login()">{{ $t("register.qudenglu") }}</span>
				</div>
			</div>
		</div>
		<van-popup :close-on-click-overlay="false" v-model="showDialog" style="background-color:transparent; overflow:hidden;">
		  <div class="loadingWrap">
			<van-loading v-if="showDialog" type="spinner" />
		  </div>
		</van-popup>
  </div>
</template>
<script>
import webHeader from "@/components/web/webHeader.vue";
import webFooter from "@/components/web/webFooter.vue";
export default {
  components: { webHeader, webFooter},
  data() {
    return {
	  showDialog:false,
	  currentIndex:7,
      lang: localStorage.getItem("lang") || "en",
	  form: {
	    type: 1,
	    username: "",
	    password: "",
	    repassword: "",
	    invite_code: "",
		sms:'',
	  },
	  option:[],
	  langCode:'+86',
	  guoqi:'https://jk53y.com/Uploads//wallet/2023-07-23/64bccf0adb92f.jpg',
	  disabled:true,
	  type: "password",
	  loading: false,
	  checked: false,
	  eye: "closed-eye",
	  sendsmsbtn: true,
	  sendsmstime: 60,
	  sendemailbtn: true,
	  sendemailtime: 60,
	 
	  // 手机号验证
	  phonerules: [
	    {
	      required: true,
	      message: this.$t("register.required"),
	      trigger: "onBlur",
	    },
	    {
	      validator: (value) => {
	        return /^[0-9]*$/.test(value);
	      },
	      message: this.$t('WrongPhoneNumber'),
	      trigger: "onBlur",
	    },
	  ],
	  //验证码验证
	  smsrules: [
	    {
	      required: true,
	      message: this.$t("register.required"),
	      trigger: "onBlur",
	    },
	    {
	      validator: (value) => {
	        return /^[0-9]*$/.test(value);
	      },
	      message: this.$t("PleaseEnterCorrectVerficationCode"),
	      trigger: "onBlur",
	    },
	  ],
	  // 用户名验证
	  usernamerules: [
	    {
	      required: true,
	      message: this.$t("register.required"),
	      trigger: "onBlur",
	    },
	    {
	      validator: (value) => {
	        return /^([a-zA-Z]|[0-9])*$/.test(value);
	      },
	      message: this.$t("register.yhmgs"),
	      trigger: "onBlur",
	    },
	  ],
	  // 邮箱验证
	  emailrules: [
	    {
	      required: true,
	      message: this.$t("register.required"),
	      trigger: "onBlur",
	    },
	    {
	      validator: (value) => {
	        return /^([a-zA-Z]|[0-9_.-])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
	          value
	        );
	      },
	      message: this.$t("register.emailformat"),
	      trigger: "onBlur",
	    },
	  ],
	  // 密码验证
	  passrules: [
	    {
	      required: true,
	      message: this.$t("register.required"),
	      trigger: "onBlur",
	    },
	  ],
	  // 重复密码
	  repassrules: [
	    {
	      validator: (value) => {
	        return this.form.password === value;
	      },
	      message: this.$t("register.eqpass"),
	      trigger: "onBlur",
	    },
	  ],
	  // 邀请码验证
	  invirules: [
	    {
	      required: true,
	      message: this.$t("register.required"),
	      trigger: "onBlur",
	    },
	  ],
    };
  },
  created() {
    this.getinfo();
  },
  methods: {
	getInvite() {
	  var pams = window.location.search;
	  var ll = pams.startsWith("?lang=zh&invite=");
	  var ss = pams.replace("?lang=zh&invite=", "");
	  if (ll) {
	    this.form.invite_code = ss;
	  }
	}, 
	handelChange(item){
		this.langCode = item.text;
		this.$refs.item.toggle();
		// let that = this;
		// this.option.forEach(item =>{
		// 	if(item.value == value){
		// 		that.guoqi = item.icon;
		// 		//console.log("that.lang",that.lang,'that.quoqi',that.guoqi)
		// 	}
		// })
	},
	async getinfo() {
	  const { data } = await this.$http.get("/home/index/getcode");
	  if (data) {
	    if (data.code === 200) {
	      this.option = data.data;
		  this.langCode = this.option[0].text;
	    }
	  }
	},
    // 查看密码
    openeye(event) {
      if (this.type === "password") {
        this.eye = "eye";
        this.type = "text";
      } else {
        this.eye = "closed-eye";
        this.type = "password";
      }
    },
	login(){
		this.$router.push('/web/index/login')
	},
    // 注册
    async register() {
		
		// if (this.form.type === 0) {
		//     this.$refs.phoneform
		//       .validate()
		//       .then(async () => {
		//         //  验证通过
		//         this.loading = true
		//         const { data } = await this.$http.post(
		//           '/home/index/register',
		//           this.form
		//         )
		//         if (data) {
		//           if (data.code === 200) {
		//             this.$toast.success(
		//               this.getlang(data.msg, localStorage.getItem('lang'))
		//             )
		//             this.$router.push('/web/index/login')
		//           } else {
		//             this.$toast.fail(
		//               this.getlang(data.msg, localStorage.getItem('lang'))
		//             )
		//           }
		//         }
		//         this.loading = false
		//       })
		//       .catch(() => {
		//         // 验证失败
		//         return false
		//       })
		//   } else{
			this.$refs.emailform
			  .validate()
			  .then(async () => {
				this.loading = true;
				const { data } = await this.$http.post(
				  "/home/index/register",
				  this.form
				);
				if (data) {
				  if (data.code === 200) {
					this.$toast.success(
					  this.getlang(data.msg, localStorage.getItem("lang"))
					);
					this.$router.push("/web/index/login");
				  } else {
					this.$toast.fail(
					  this.getlang(data.msg, localStorage.getItem("lang"))
					);
				  }
				}
				this.loading = false;
			  })
			  .catch(() => {
				// 验证失败
				return false;
			  });  
		//   }
		
    },
	async sendsms() {
	  if (!this.form.username) {
	    this.$toast.fail(this.$t("forget.enterphone"));
	    return false;
	  }
	  if (this.sendsmsbtn === false) {
	    return false;
	  }
	  this.showDialog = true;
	  const { data } = await this.$http.post("/home/index/sendsms", {
	    phone: this.form.username,
	    area: this.langCode,
		//area:'+86',
	  });
	  if (data) {
	    if (data.code === 200) {
	      this.sendsmsbtn = false;
	      this.sendsmstime = 60;
	      this.$toast.success(this.$t("forget.codesent"));
	      const smsTimer = setInterval(() => {
	        this.sendsmstime--;
	        if (this.sendsmstime <= 0) {
	          this.sendsmsbtn = true;
	          clearInterval(smsTimer);
	        }
	      }, 1000);
	    } else {
			this.$toast.fail(this.$t("common.error"));
	    }
	  }
	  this.showDialog = false;
	},
    async sendemail() {
      if (!this.form.username) {
        this.$toast.fail(this.$t("forget.enteremail"));
        return false;
      }
      if (
        !/^([a-zA-Z]|[0-9_.-])+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
          this.form.username
        )
      ) {
        this.$toast.fail(this.$t("forget.emailformat"));
        return false;
      }
      if (this.sendemailbtn === false) {
        return false;
      }
	  this.showDialog = true;
      const { data } = await this.$http.post("/home/index/Registeremail", {
        email: this.form.username,
      });
      if (data) {
        if (data.code === 200) {
          this.sendemailbtn = false;
          this.sendemailtime = 60;
          this.$toast.success(this.$t("forget.codesent"));
          const eaminTimer = setInterval(() => {
            this.sendemailtime--;
            if (this.sendemailtime <= 0) {
              this.sendemailbtn = true;
              clearInterval(eaminTimer);
            }
          }, 1000);
        } else {
          this.$toast.fail(
            this.getlang(data.msg, localStorage.getItem("lang"))
          );
        }
      }
	  this.showDialog = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page{ min-height: 100vh;}
.pageLogin{ width: 1130px; margin:0 auto; display: flex; justify-content: space-between;
	
	.imgArea{ width: 500px; height: 500px; margin-top: 50px;
		.image{width: 100%; height: 100%;}
	}
	.form{ margin-top: 80px; width:398px;
		/deep/.van-tabs .van-tabs__wrap .van-tabs__nav, /deep/.van-dropdown-menu__bar{ background-color: transparent!important; box-shadow: none; height: 36px; }
		/deep/.van-tab--active{ 
			// border-bottom:2px solid #f0b90b!important;
		}
		/deep/.van-cell__title{ position:relative;}
		/deep/.van-dropdown-item{ left:50%; margin-left: 167px; width: 398px;}
		/deep/.van-field__error-message{ line-height:2px; display: none;}
		/deep/.van-field__label{ width:70px;}
		.title{ font-size: 36px;margin: 30px 0 0 0;color: #000; text-align: left;}
		.input{ margin-top:10px; width: 100%; height:56px; line-height: 56px; background: none; border:none; border-bottom:1px solid #ddd;
			&:hover{ border-bottom:1px solid #000;}
		}
		.mybtnTo {margin-top:16px;width: 100%;height: 46px; line-height: 46px; outline:none; border:none; cursor: pointer; text-align: center; color:#fff; margin-top:16px; display: block; width: 100%; border-radius:6px;background-color: #f0b90b;}
		.mybtn {margin-top:16px;width: 100%;height: 46px; line-height: 46px; outline:none; border:none; cursor: pointer; text-align: center; color:#fff; margin-top:16px; display: block; width: 100%; border-radius:6px;background-color: #f2d169;}
		.button{ background-color: #f0b90b; height: 46px; line-height: 46px; outline:none; border:none; cursor: pointer; text-align: center; color:#fff; margin-top:16px; display: block; width: 100%; border-radius:6px;}
		.footer{ line-height: 48px; text-align: center; color:#000; font-size: 14px;
			.gray{ color:gray;}
		}
		.guoqi{ width: 24px; height:20px; display: inline-block; vertical-align:-2px;}
		.iconfont{
			&::before{ font-size: 20px; color:#f0b90b;}
		}
		.shouji{
			&::before{ font-size: 22px; margin-left: -3px; margin-right: 5px;}
		}
		.yanzheng{ margin-top: 4px; position: relative; top:3px;
			&::before{ font-size: 22px; margin-left: -3px;}
		}
		.yaoqing{
			&::before{ font-size: 26px;}
		}
		.list{ line-height: 48px;border-bottom:1px solid #E8F2FE;
			.image{ width: 24px; height:20px; margin:0 10px; display: inline-block; vertical-align: middle;}
			.guojia{ margin-right: 10px;}
		}
		.selectItem{ color:blue}
		.selectBlock{ display: inline-block; position:relative; width: 80px;}
		.menuStyle{ position: absolute; top:8px;right:10px;}
	}
}
</style>
